@import "./Themes/Themes.scss";

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: linear-gradient(to bottom right, $school-green, $school-gold);
  background: url(Images/fancy-background.png);
  background-size: contain;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: $dark-color;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: $school-gold;
}
