@import "../Themes/Themes.scss";

.container {
    padding: 2rem 1rem 1rem 1rem;
    margin: auto;
    width: 85%;
    @include checkMobile(max-width, 25rem, $global-max-width);
}

.levelTitle {
    padding: 1rem 0;
    line-height: 3rem !important;
}

.card-cover-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.sponsorCover {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.levelSection {
    @include checkMobile(
        (flex-direction, margin), 
        (column, "1rem auto 3rem auto"), 
        (row, "3rem auto")
    );
    align-items: center;
    display: flex;
    border-radius: 1rem;
    justify-content: center;
}

.orgCard {
    display: flex;
    text-align: center;
    align-items: center;
    @include checkMobile(
        (margin, margin-top), 
        (auto, 1rem), 
        (auto, "")
    );
}

.sponsorCards {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
    box-shadow: inset 0px 0px 10px 2px black;
    border-radius: 0px 1rem 1rem 0px;
    animation: growIn 750ms linear 100ms;
    animation-fill-mode: forwards;
    overflow: hidden;
    @include checkMobile(flex-direction, column, row);
}

.interested-container {
    margin: 2rem auto;
    @include checkMobile(width, 85%, 60%);
    padding: 1rem;
    max-width: $global-max-width;
}
