@import "../Themes/Themes.scss";

.NavBarSelection.MuiTab-textColorInherit {
    color: $main-white;
}

.NavBarSelection.Mui-selected {
    @include checkMobile(color, $school-gold, $dark-color);
    cursor: default;
}

.NavBarSelection.MuiTab-root {
    @media (min-width:0px) {
        font-size: 1rem;
    }
    @media (min-width:768px) {
        font-size: 1.25rem;
    }
    @media (min-width:992px) {
        font-size: 1.35rem;
    }
    @media (min-width:2000px) {
        font-size: 1.5rem;
    }
}

.NavBarSelection {
    transition: color linear 250ms;
    &:hover {
        opacity: 1;
    }
}

.content {
    margin-top: 5rem;
}

.icon {
    vertical-align: middle;
}

.footer {
    @include checkMobile(
        (flex-direction, height, padding), 
        (column, fit-content, 2rem), 
        (row, 30vh, "")
    );
    display: flex;
    background-color: $dark-color;
    align-items: center;
    justify-content: center;
    color: $school-gold;
    box-shadow: 0px 0px 10px black;
    text-align: center;
    overflow: hidden;
}

.footer-section {
    @include checkMobile(width, "", 20%);
    .MuiIconButton-root {
        color: $school-gold;
    }
}

.paypal {
    border-radius: 0.5rem;
    padding: 0.5rem;
    &:hover {
        box-shadow: 0 0 10px 0 white;
    }
}

iframe {
    border: none;
    max-width: 90vw;
    max-height: 80vh;
}