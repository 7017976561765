/* Colors */
$school-gold: #B3A369;
$dark-color: #212B31;
$school-green: #00703c;
$main-white: #FFF;
$link-green: #5DA355;

/* Global Constants */
$global-max-width: 90rem;

/* Dynamic Fonts */
h1.MuiTypography-h1 {
    @media (min-width:0px) {
        font-size: 2rem;
    }
    @media (min-width:768px) {
        font-size: 2.25rem;
    }
    @media (min-width:992px) {
        font-size: 2.5rem;
    }
    @media (min-width:1200px) {
        font-size: 3rem;
    }
}

h2.MuiTypography-h2 {
    @media (min-width:0px) {
        font-size: 1.75rem;
    }
    @media (min-width:992px) {
        font-size: 2rem;
    }
    @media (min-width:1200px) {
        font-size: 2.25rem;
    }
}

h3.MuiTypography-h3 {
    @media (min-width:0px) {
        font-size: 1.5rem;
    }
    @media (min-width:992px) {
        font-size: 1.75rem;
    }
    @media (min-width:1200px) {
        font-size: 2rem;
    }
}

p.MuiTypography-body1, h6.MuiTypography-subtitle1 {
    @media (min-width:0px) {
        font-size: 1.25rem;
    }
    @media (min-width:1200px) {
        font-size: 1.75rem;
    }
}

p.MuiTypography-body2 {
    @media (min-width:0px) {
        font-size: 1.25rem;
    }
    @media (min-width:1200px) {
        font-size: 1.5rem;
    }
}

/* Animations */
@keyframes fadeIn {
    0% {
      opacity: 0;
    }  
    100% {
      opacity: 1;
    }
}

@keyframes growIn {
    0% {
        width: 0%;
    }
    100% {
        width: 90%;
    }
}

@keyframes slideInDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

/* Mobile Conditional Properties */
@mixin checkMobile($properties, $valuesTrue, $valuesFalse) {
    @media screen and (max-width: 1249px) {
        @for $i from 1 to length($properties)+1 {
            #{nth($properties, $i)}: #{nth($valuesTrue, $i)};
        }
    }
    @media screen and (min-width: 1250px) {
        @for $i from 1 to length($properties)+1 {
            #{nth($properties, $i)}: #{nth($valuesFalse, $i)};
        }
    }
}